import React, { createContext, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import {
  HandleBetTime,
  HandleDrawNumber,
  HandleHistoryResults,
  HandleHubJoin,
  HandleRoundStatus,
  HandleSecToNext,
} from "../HubHabdlers/Handlers";
import {
  Construct48Balls,
  Construct49Balls,
  fromSecToMins,
} from "../HubHabdlers/Utils";
import ResultsComponent from "./ResultsComponent";
import DrawingComponent from "./DrawingComponent";

export const GeneralContext = createContext(null);

const RootComponent = () => {
  const qParams = new URLSearchParams(window.location.search);
  const [connection, setConnection] = useState(null);

  const [gameKer, setGameKer] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [ballsArray, setBallsArray] = useState(null);

  const [switchScreen, setSwitchScreen] = useState(false);

  const [hubJoinedMsg, setHubJoinedMsg] = useState(null);
  const [drawNumberMsg, setDrawNumberMsg] = useState(null);

  const [roundNumber, setRoundNumber] = useState(null);
  const [jackpotCode, setJackpotCode] = useState(null);
  const [jackpotValue, setJackpotValue] = useState(null);
  const [betTimeRemains, setBetTimeRemains] = useState(null);
  const [historyResult, setHistoryResult] = useState(null);

  useEffect(() => {
    const newConn = new signalR.HubConnectionBuilder()
      .withUrl("https://rb-sixgame.syncrobet.ro/gamehub", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return "My Access Token";
        },
      })
      .withAutomaticReconnect()
      .build();
    setConnection(newConn);
  }, []);

  useEffect(() => {
    let xx = qParams.get("ver");
    let yy = qParams.get("id");
    if (xx != null) setGameKer(xx);
    else setGameKer("48");
    if (yy != null) setShopId(yy);
    else setShopId("A02CCA32-1867-44A3-ACDB-241EB3AAF90F");
  }, []);

  useEffect(() => {
    if (gameKer == "48") {
      let yy = Construct48Balls();
      setBallsArray(yy);
    } else if (gameKer == "49") {
      let yyy = Construct49Balls();
      setBallsArray(yyy);
    }
  }, [gameKer]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.invoke("join", shopId).catch((err) => {
            return console.error(err.toString());
          });
        })
        .then(() => {
          connection.on("hubJoined", (message) =>
            HandleHubJoin(message, setHubJoinedMsg)
          );
        })
        .then(() => {
          connection.on("drawNumber", (message) => {
            HandleDrawNumber(message, setDrawNumberMsg);
          });
        })
        .then(() => {
          connection.on("roundStatus", (message) => {
            HandleRoundStatus(message, setDrawNumberMsg, setSwitchScreen);
          });
        })
        .then(() => {
          connection.on("betTimeRemains", (message) => {
            HandleBetTime(message, setBetTimeRemains);
          });
        })
        .then(() => {
          connection.on("historyResults", (message) => {
            HandleHistoryResults(message, setHistoryResult);
          });
        });
    }
  }, [connection]);

  useEffect(() => {
    if (betTimeRemains < 11) setSwitchScreen(true);
    if (betTimeRemains >= 11) setSwitchScreen(false);
  }, [betTimeRemains]);

  useEffect(() => {
    if (hubJoinedMsg != null) {
      setRoundNumber(hubJoinedMsg.RoundNumber);
      setJackpotCode(hubJoinedMsg.RoundInfo.JackpotInfo.Code);
      setJackpotValue(hubJoinedMsg.RoundInfo.JackpotInfo.Value);
      setHistoryResult(hubJoinedMsg.HistoryResults[0]);
    }
  }, [hubJoinedMsg]);

  const contextValues = {
    ballsArray: [ballsArray, setBallsArray],

    roundNumber: [roundNumber, setRoundNumber],
    jackpotCode: [jackpotCode, setJackpotCode],
    jackpotValue: [jackpotValue, setJackpotValue],
    betTimeRemains: [betTimeRemains, setBetTimeRemains],
    historyResult: [historyResult, setHistoryResult],

    drawNumberMsg: [drawNumberMsg, setDrawNumberMsg],
  };

  useEffect(() => {
    if (historyResult != null) {
      setRoundNumber(historyResult.RoundNumber);
    }
  }, [historyResult]);

  useEffect(() => {
    if (drawNumberMsg != null) {
      setRoundNumber(drawNumberMsg.RoundNumber);
      setJackpotCode(drawNumberMsg.JackpotCode);
      setSwitchScreen(true);
    }
  }, [drawNumberMsg]);

  const RenderCondition = () => {
    if (switchScreen === true) {
      return <DrawingComponent />;
    } else return <ResultsComponent />;
  };

  return (
    ballsArray && (
      <GeneralContext.Provider value={contextValues}>
        {RenderCondition()}
      </GeneralContext.Provider>
    )
  );
};

export default RootComponent;
