export const fromSecToMins = (seconds) => {
  let mins = Math.floor(seconds / 60);
  let sec = seconds - mins * 60;
  if (sec < 10) sec = "0" + sec;
  return mins + ":" + sec;
};

export const Construct48Balls = () => {
  let fullArray = [];

  for (let i = 1; i <= 48; i++) {
    let obj = {
      value: i,
      color: null,
    };
    if (i % 8 === 1) {
      obj.color = "./ballsbk/red.png";
    } else if (i % 8 === 2) {
      obj.color = "./ballsbk/green.png";
    } else if (i % 8 === 3) {
      obj.color = "./ballsbk/blue.png";
    } else if (i % 8 === 4) {
      obj.color = "./ballsbk/purple.png";
    } else if (i % 8 === 5) {
      obj.color = "./ballsbk/brown.png";
    } else if (i % 8 === 6) {
      obj.color = "./ballsbk/yellow.png";
    } else if (i % 8 === 7) {
      obj.color = "./ballsbk/orange.png";
    } else if (i % 8 === 0) {
      obj.color = "./ballsbk/black.png";
    }

    fullArray.push(obj);
  }

  return fullArray;
};

export const Construct49Balls = () => {
  let fullArray = [];
  for (let i = 1; i <= 49; i++) {
    let obj = {
      value: i,
      color: null,
    };
    if (i % 7 === 1) {
      obj.color = "./ballsbk/blue.png";
    } else if (i % 7 === 2) {
      obj.color = "./ballsbk/purple.png";
    } else if (i % 7 === 3) {
      obj.color = "./ballsbk/red.png";
    } else if (i % 7 === 4) {
      obj.color = "./ballsbk/green.png";
    } else if (i % 7 === 5) {
      obj.color = "./ballsbk/yellow.png";
    } else if (i % 7 === 6) {
      obj.color = "./ballsbk/orange.png";
    } else if (i % 7 === 0) {
      obj.color = "./ballsbk/black.png";
    }

    fullArray.push(obj);
  }
  return fullArray;
};

export const HelpFirstBallColor = (message) => {
  if (message === "Red") {
    return "./ballsbk/red.png";
  }
  if (message === "Black") {
    return "./ballsbk/black.png";
  }
  if (message === "Blue") {
    return "./ballsbk/blue.png";
  }
  if (message === "Brown") {
    return "./ballsbk/brown.png";
  }
  if (message === "Green") {
    return "./ballsbk/green.png";
  }
  if (message === "Orange") {
    return "./ballsbk/orange.png";
  }
  if (message === "Violet") {
    return "./ballsbk/purple.png";
  }
  if (message === "Yellow") {
    return "./ballsbk/yellow.png";
  }
};

export const HelpFirstBallColorV2 = (message) => {
  if (message === "Red") {
    return "redBack";
  }
  if (message === "Black") {
    return "blackBack";
  }
  if (message === "Blue") {
    return "blueBack";
  }
  if (message === "Brown") {
    return "brownBack";
  }
  if (message === "Green") {
    return "greenBack";
  }
  if (message === "Orange") {
    return "orangeBack";
  }
  if (message === "Violet") {
    return "purpleBack";
  }
  if (message === "Yellow") {
    return "yellowBack";
  }
};

export const HelpFirstBallLabel = (message) => {
  if (message === "Red") {
    return "Rosu";
  }
  if (message === "Black") {
    return "Negru";
  }
  if (message === "Blue") {
    return "Albastru";
  }
  if (message === "Brown") {
    return "Maro";
  }
  if (message === "Green") {
    return "Verde";
  }
  if (message === "Orange") {
    return "Portocaliu";
  }
  if (message === "Violet") {
    return "Violet";
  }
  if (message === "Yellow") {
    return "Galben";
  }
};
export const HelpUnderOverFirstBall = (message) => {
  if (message === "Under 24.5") return "Sub 24.5";
  return "Peste 24.5";
};
export const HelpEvenOddValue = (message) => {
  if (message === "Odd") return "I";
  return "P";
};
export const HelpEvenOddLabel = (message) => {
  if (message === "Odd") return "Impar";
  return "Par";
};

export const HelpSumLabel = (message) => {
  if (message === "Under 122.5") return "Sub 122.5";
  return "Peste 122.5";
};
