import "./App.css";
import RootComponent from "./Components/RootComponent";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "es6-symbol/implement";
function App() {
  return (
    <div className="App">
      <RootComponent />
    </div>
  );
}

export default App;
