export const HandleHubJoin = (message, setter) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg));
};

export const HandleBetTime = (message, setter) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg));
};
export const HandleSecToNext = (message, setter) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg));
};
export const HandleDrawNumber = (message, setter) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg));
};
export const HandleRoundStatus = (message, setter, setSwitch) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  if (msg == "Opened") {
    setter(null);
    setSwitch(false);
  }
};
export const HandleHistoryResults = (message, setter) => {
  var msg = message
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  setter(JSON.parse(msg)[0]);
};
